/**
 * Icon
 */

.icon {
  position: relative;
  overflow: hidden;
  /* width: 50px;
  height: 50px; */
  /* display: inline-block; */

  text-decoration: none;
  /* text-align: center; */
  line-height: 30px;
  /* font-size: 24px; */
  font-family: sans-serif;
}

/**
   * The "shine" element
   */

.icon:after {
  animation: shine 2s ease-in-out infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  /* color: white; */
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;
  /* transform: rotate(30deg); */

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* Hover state - trigger effect */

/* Active state */

.icon:active:after {
  opacity: 0;
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
